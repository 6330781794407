import { buildArtisnHeaders } from "@multicines/services";
import { useGeoStore, useShoppingCartStore } from "@multicines/stores";
import { applyBenefit, getShoppingCartProducts } from "artisn/shopping-cart";
import { Benefit } from "artisn/types";
import CloseSVG from "images/close.svg";
import React, { useState } from "react";

import Styles, { ModalHeaderStyled } from "./CouponsModal.styles";
import { CouponsModalProps as Props } from "./CouponsModal.types";
import BenefitsList from "../BenefitsList/BenefitsList";
import RedeemCouponInput from "components/Cart/RedeemCoupon/RedeemCouponInput/RedeemCouponInput";
import Button from "components/global/Button/Button";
import Modal from "components/global/Modal/Modal";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useShippingCost from "hooks/useShippingCost";
import { useAuthStore } from "stores/auth/auth.store";
import { goToBenefitDetail } from "utils/seo.utils";

const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const CouponsModal: React.FC<Props> = props => {
  const { opened, benefits, className, redeemCouponError } = props;
  const { setRedeemCouponError, onClose, redeemHandler } = props;
  const { openProductModal } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const setTemporalBenefit = useShoppingCartStore(
    store => store.setTemporalBenefit
  );
  const shippingCost = useShippingCost();
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const [couponCode, setCouponCode] = useState("");
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>();
  const [applyBenefitError, setApplyBenefitError] = useState("");
  const t = useI18n();
  const { lat, lng } = selectedCoordinates ?? {};
  const empty = benefits && benefits.length === 0;
  const { benefits: cartBenefits } = shoppingCart ?? {};
  const selectedCartBenefit = cartBenefits ? cartBenefits[0] : undefined;
  const disable = empty || !selectedBenefit || !!selectedCartBenefit;

  const resetLocalStates = () => {
    setSelectedBenefit(undefined);
    setApplyBenefitError("");
    setRedeemCouponError("");
    setCouponCode("");
  };

  const closeHandler = () => {
    resetLocalStates();
    onClose?.();
  };

  const applyHandler = async () => {
    if (!selectedBenefit || !shoppingCart) return;

    try {
      setApplyBenefitError("");
      const { benefitId, type } = selectedBenefit;
      if (type === "PRODUCT") {
        setTemporalBenefit(selectedBenefit);

        if (WITH_PRODUCT_MODAL) {
          closeHandler();
          openProductModal();
          return;
        }
        goToBenefitDetail(selectedBenefit);
        return;
      }

      if (type === "ALTER_DELIVERY") {
        setTemporalBenefit(undefined);
        if (!getShoppingCartProducts(shoppingCart).length) {
          throw new Error(
            "Can't apply benefit because there are no products in the cart"
          );
        }
        // TODO: SDK
        if (typeof isAnonymous === "undefined") return;
        if (typeof uid === "undefined") return;

        await applyBenefit(
          {
            benefitId,
            latitude: lat,
            longitude: lng,
            shippingCost,
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            apiURL: API_URL,
            accountId: ACCOUNT_ID,
            anonymous: isAnonymous,
            customerId: uid
          },
          await buildArtisnHeaders()
        );
        closeHandler();
      }
    } catch (e) {
      notify(e, "applyHandler - CouponsModal");
      setApplyBenefitError(e.message);
    }
  };

  const modalHeaderNode = (
    <ModalHeaderStyled>
      <p className="CouponsModal__header">{t.coupons.metaTitle}</p>
    </ModalHeaderStyled>
  );

  return (
    <Modal
      {...props}
      closeOnClickOutside
      opened={opened}
      header={modalHeaderNode}
      closeIcon={<CloseSVG />}
      backdropConfig={{ onClick: closeHandler }}
      onClose={closeHandler}
      color="transparent"
    >
      <Styles className={`CouponsModal ${className}`}>
        <div className="CouponsModal__header">
          <div className="CouponsModal__input">
            <RedeemCouponInput
              disabled={!benefits}
              couponCode={couponCode}
              onRedeem={redeemHandler}
              setCouponCode={setCouponCode}
            />
          </div>
          {redeemCouponError ? (
            <p className="CouponsModal__error CouponsModal__redeem-error">
              {redeemCouponError}
            </p>
          ) : null}
        </div>
        <BenefitsList
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          setSelectedBenefit={setSelectedBenefit}
        />
        <div className="CouponsModal__apply">
          {applyBenefitError ? (
            <p className="CouponsModal__error CouponsModal__apply-error">
              {applyBenefitError}
            </p>
          ) : null}
          <Button
            className="CouponsModal__apply__button"
            disabled={disable}
            onClick={applyHandler}
          >
            {t.common.apply}
          </Button>
        </div>
      </Styles>
    </Modal>
  );
};

CouponsModal.defaultProps = {
  className: ""
};

export default CouponsModal;
