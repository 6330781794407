import { useProductsStore } from "@multicines/stores";
import { Button } from "artisn-ui-react";
import React, { MouseEvent } from "react";

import Styles from "./RadioButtonModifier.styles";
import { RadioButtonModifierProps as Props } from "./RadioButtonModifier.types";
import ModifierPrices from "../ModifierPrices/ModifierPrices";
import useOnMount from "hooks/useOnMount";
import { getMostChosenOptionKey } from "utils/product.utils";

const RadioButtonModifier: React.FC<Props> = props => {
  const { modifier, disabled = false, preferences } = props;
  const { amount, name, totals, id } = modifier;
  const { handleChange, groupId, data } = modifier;
  const { prices } = data;
  const selectedProduct = useProductsStore(store => store.selectedProduct);

  const iconActiveClassName = amount ? "RadioButtonModifier__icon--active" : "";

  const onClickHandle = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    if (amount) {
      handleChange(0);
      return;
    }
    handleChange(1);
  };

  useOnMount(() => {
    const mostChosenOptionKey = getMostChosenOptionKey(preferences);
    if (selectedProduct || !mostChosenOptionKey) return;
    const key = `${groupId}-${id}`;
    if (key === mostChosenOptionKey) {
      handleChange(preferences[mostChosenOptionKey].amount);
    }
  });

  return (
    <Styles className="RadioButtonModifier" disabled={disabled}>
      <ModifierPrices name={name} totals={totals} prices={prices} />
      <Button
        className="RadioButtonModifier__button"
        id={id}
        onClick={onClickHandle}
        value={amount}
      >
        <div className={`RadioButtonModifier__icon ${iconActiveClassName}`} />
      </Button>
    </Styles>
  );
};

RadioButtonModifier.defaultProps = {};

export default RadioButtonModifier;
