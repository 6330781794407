import styled from "styled-components";

import { CounterModifierStyledProps as Props } from "./CounterModifier.types";

const CounterModifierStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  .CounterModifier {
    &__info {
      display: flex;
      align-items: center;
    }

    &__counter {
      margin-right: 0.8rem;

      .Counter {
        &__quantity {
          font-size: 1rem;
        }

        &__button:disabled {
          path {
            fill: var(--palette-primary);
          }
        }
      }
    }

    &__image {
      margin: 0 0.8rem;
    }
  }
`;

export default CounterModifierStyled;
