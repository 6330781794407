import { useShoppingCartStore } from "@multicines/stores";
import { useGeoStore } from "@multicines/stores";
import { useCataloguesStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { createShoppingCart, findProduct } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";
import { useEffect, useState } from "react";

import { UseAddToCartWishlistProps } from "./AddToWishlistButton.types";
import CONSTANTS from "config/constants";
import useShippingCost from "hooks/useShippingCost";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const { CONTENT_TYPE, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { logAddProductToWishlist } = events.shoppingCart;
const { logRemoveProductFromWishlist } = events.shoppingCart;

export const useAddToWishlist = (props: UseAddToCartWishlistProps) => {
  const { product, onFinish, config } = props;
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [ticketsStore] = selectedStore ?? [];
  const { shoppingCartName } = config;
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const shippingCost = useShippingCost();
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const wishlist = useShoppingCartStore(store => store.wishlist);
  const { removeProduct, addProduct } = useShoppingCart();
  const [inCart, setInCart] = useState(false);

  const { productId } = product ?? {};

  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = async () => {
    if (!product) return;
    if (!ticketsStore) {
      console.error("AddToWishlistButton: ticketsStore is undefined");
      return;
    }

    const { id: cartId, name: cartName } = wishlist ?? {};

    if (!wishlist) {
      await createShoppingCart(
        {
          anonymous: isAnonymous!,
          accountId: ACCOUNT_ID,
          customerId: uid!
        },
        {
          channelId: +selectedCatalogueId,
          shippingCost,
          latitude: lat ?? 0,
          longitude: lng ?? 0,
          name: shoppingCartName
        }
      );
    }

    if (inCart) {
      // TODO: SDK review it
      removeProduct?.(product, { shoppingCartName, store: ticketsStore });
      setInCart(false);

      if (cartId && product) {
        logRemoveProductFromWishlist({
          cartId,
          product: product as CartProduct,
          contentType: CONTENT_TYPE
        });
      }

      onFinish?.();
      return;
    }

    addProduct?.(product, {
      amount: 1,
      store: ticketsStore,
      shoppingCartName
    });
    setInCart(true);

    if (cartId && cartName) {
      logAddProductToWishlist({
        cartId,
        cartName,
        product: product as CartProduct,
        contentType: CONTENT_TYPE
      });
    }

    onFinish?.();
  };

  useEffect(() => {
    if (typeof productId === "undefined") return;
    (async () => {
      const inCart = !!(await findProduct({
        productId,
        shoppingCartName,
        accountId: ACCOUNT_ID,
        customerId: uid!
      }));
      setInCart(inCart);
    })();
  }, [productId, shoppingCartName, wishlist, uid]);

  return { onClick, inCart };
};
