import React from "react";

import Styles from "./CounterModifier.styles";
import { CounterModifierProps as Props } from "./CounterModifier.types";
import ModifierPrices from "../ModifierPrices/ModifierPrices";
import Counter from "components/global/Counter/Counter";

const CounterModifier: React.FC<Props> = props => {
  const { modifier, maxDisabled = false, disabled = false } = props;
  const { amount, name, max, min, totals } = modifier;
  const { handleChange, data } = modifier;
  const { prices } = data;
  const totalDisabled = maxDisabled && !amount;

  return (
    <Styles className="CounterModifier">
      <ModifierPrices name={name} totals={totals} prices={prices} />
      <div className="CounterModifier__info">
        <Counter
          className="CounterModifier__counter"
          initialValue={amount ?? min}
          max={max}
          min={min}
          onChange={handleChange}
          disabled={disabled ?? totalDisabled}
          maxDisabled={maxDisabled}
        />
      </div>
    </Styles>
  );
};

CounterModifier.defaultProps = {};

export default CounterModifier;
