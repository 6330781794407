import styled from "styled-components";

import { RadioButtonModifierStyledProps as Props } from "./RadioButtonModifier.types";

const RadioButtonModifierStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  &.RadioButtonModifier .Button {
    box-shadow: none;
    padding: 0;
    cursor: ${props => (props.disabled ? "default" : "pointer")};

    :hover {
      box-shadow: none;
    }
  }

  .RadioButtonModifier {
    &__button {
      display: flex;
      border: none;
      align-items: center;
      background-color: unset;
    }

    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      border: 0.1rem solid var(--palette-gray-s0-l80);
      border-radius: 10rem;

      &--active {
        border: 0.7rem solid
          ${props =>
            props.disabled
              ? "var(--palette-primary-light)"
              : "var(--palette-primary)"};
      }
    }

    &__image {
      display: none;
    }

    &__text {
      font-family: "Inter", sans-serif;
      font-size: 1.2rem;
      color: ${props =>
        props.disabled
          ? "var(--palette-gray-s0-l70-a48)"
          : "var(--palette-black-s0-l10)"};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &--active {
        font-weight: 600;
      }
    }
  }
`;

export default RadioButtonModifierStyled;
