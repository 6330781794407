import { useCountriesStore, useShoppingCartStore } from "@multicines/stores";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { ShoppingCartSummary as ShoppingCartSummaryUI } from "artisn-ui-react";
import React, { useMemo } from "react";

import Styles from "./ShoppingCartSummary.styles";
import { ShoppingCartSummaryProps as Props } from "./ShoppingCartSummary.types";

const { Total } = ShoppingCartSummaryUI;

const ShoppingCartSummary: React.FC<Props> = props => {
  const { className } = props;
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const selectedCountry = useCountriesStore(state => state.selectedCountry);
  const { currency: defaultCurrency, locale } = selectedCountry;
  const { currencySymbol: defaultSymbol } = selectedCountry;
  const shoppingCartTotal = shoppingCart
    ? getShoppingCartTotal(shoppingCart)
    : null;
  const defaultFormatter = useMemo(
    () => ({ currency: defaultCurrency, symbol: defaultSymbol, locale }),
    [defaultCurrency, defaultSymbol, locale]
  );

  if (!shoppingCartTotal) return null;

  return (
    <Styles
      className={`ShoppingCartSummary ${className}`}
      shoppingCartTotals={shoppingCartTotal}
      formatter={defaultFormatter}
    >
      <Total className="ShoppingCartSummary__total" />
    </Styles>
  );
};

ShoppingCartSummary.defaultProps = {
  className: ""
};

export default ShoppingCartSummary;
