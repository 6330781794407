import styled from "styled-components";

import { ModifiersGroupStyledProps as Props } from "./ModifiersGroup.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const ModifiersGroupStyled = styled.div<Props>`
  color: var(--palette-text-white);
  background-color: var(--palette-mid-blue-s15-l8);

  && .Pocket {
    &__header {
      background-color: var(--palette-black-s15-l8);

      @media (max-width: ${tablet}px) {
        padding: 0;
      }

      &__title {
        flex: 1;
        justify-content: space-between;
      }
    }

    &__icon {
      margin-right: 4rem;

      path {
        fill: var(--palette-white);
      }
    }

    &__content > *:not(.ModifiersGroup__divider) {
      padding: 1.6rem 2.4rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l30-a40);
      background-color: var(--palette-dark-blue-s13-l11);

      @media (max-width: ${tablet}px) {
        padding: 1.6rem;
      }
    }

    &__content > *:not(.ModifiersGroup__divider):last-child {
      border-bottom: none;
    }
  }

  &:last-of-type {
    .ModifiersGroup__divider {
      display: none;
    }
  }

  .ModifiersGroup {
    &__pocket-title {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__name {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      color: var(--palette-white);
    }

    &__description {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l70);
      line-height: 1.6rem;
      font-weight: 400;
      padding-top: 0.4rem;
      white-space: pre-wrap;
    }

    &__error {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--palette-red-s65-l60);
    }

    &__required {
      display: flex;
      align-items: center;
      padding: 0 0.8rem;
      color: var(--palette-gray-s0-l80);
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &__divider {
      @media (min-width: ${tablet}px) and (max-width: ${tablet}px) {
        margin: 0;
      }
    }
  }
`;

export default ModifiersGroupStyled;
