import styled from "styled-components";

import { BenefitsListStyledProps as Props } from "./BenefitsList.types";
import CONSTANTS from "config/constants";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const BenefitsListStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1.2rem;

  .BenefitsList {
    &__buttonGroup {
      flex-direction: column;
      align-items: start;
      padding: 0 1.6rem;

      @media (max-width: ${tablet}px) {
        padding: 0;
      }
    }

    &__button {
      border: none;
      width: 100%;
      flex: 1;
      padding: 0;
      cursor: pointer;

      &.Button--active {
        border: none;

        .CouponDetail__left__icon {
          border: 0.7rem solid var(--palette-primary);
        }
      }

      @media (max-width: ${mobile}px) {
        padding: 0;
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;

      &__title {
        font-size: 2rem;
        color: var(--palette-gray-s0-l80);
        padding: 1.6rem 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__message {
        font-size: 1.6rem;
        color: var(--palette-gray-s0-l80);
        text-align: center;
      }
    }
  }
`;

export default BenefitsListStyled;
