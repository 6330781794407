import styled from "styled-components";

import { InfoMessageStyledProps as Props } from "./InfoMessage.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const InfoMessageStyled = styled.div<Props>`
  margin-top: 1.6rem;
  padding: 1rem;
  border-radius: 1.6rem;
  border: 0.1rem solid var(--palette-yellow-s97-l54);
  display: flex;
  align-items: flex-start;
  width: fit-content;

  .InfoMessage {
    &__svg {
      width: 2rem;
      height: 2rem;

      @media (max-width: ${tablet}px) {
        width: 4rem;
      }
    }

    &__text {
      margin-left: 0.8rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--palette-white);
    }
  }
`;

export default InfoMessageStyled;
