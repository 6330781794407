import { ThumbnailCarousel, ZoomImage } from "artisn-ui-react";
import { Image, LightboxImage } from "artisn-ui-react";
import React, { useState } from "react";

import { imageConfigurations } from "./ProductDetailsImage.helpers";
import { imageLightboxConfigurations } from "./ProductDetailsImage.helpers";
import Styles from "./ProductDetailsImage.styles";
import { ProductDetailsImageProps as Props } from "./ProductDetailsImage.types";
import InfoMessage from "components/global/InfoMessage/InfoMessage";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";

const ProductDetailsImage: React.FC<Props> = props => {
  const { images, name, description, isDiners } = props;
  const [imageIndex, setImageIndex] = useState(0);
  const { width } = useWindowSize();
  const [showLightbox, setShowLightbox] = useState(false);

  const t = useI18n().cart;

  const doubleClickHandler = (index: number) => {
    setImageIndex(index);
    setShowLightbox(true);
  };

  const renderMobileNode = () => {
    if (!images || !images.length) return null;

    const selectedImage = images[imageIndex];

    return (
      <>
        <Image
          alt={selectedImage.name}
          image={selectedImage.url}
          config={imageConfigurations(width)}
          placeholder="blur"
          className="ProductDetailsImage__image--mobile"
        />
        <div className="ProductDetailsImage__text">
          <p className="ProductDetailsImage__text__name">{name}</p>
          <p className="ProductDetailsImage__text__description">
            {description}
          </p>
          {isDiners ? (
            <InfoMessage
              message={t.dinersAdvisement}
              className="ProductDetailsImage__text__advisement"
            />
          ) : null}
        </div>
      </>
    );
  };

  const renderDesktopNode = () => {
    if (!images || !images.length) return null;

    const selectedImage = images[imageIndex];
    return (
      <>
        <ThumbnailCarousel
          slideWidth={64}
          slideHeight={64}
          images={images}
          height={280}
          className="ProductDetailsImage__thumbnail-carousel ProductDetailsImage__thumbnail-carousel--desktop"
          vertical
          onClickItem={setImageIndex}
          onHoverItem={setImageIndex}
          onDoubleClickItem={doubleClickHandler}
        />
        <Image
          alt={selectedImage.name}
          image={selectedImage}
          config={imageConfigurations(width)}
          placeholder="blur"
          className="ProductDetailsImage__image--tablet"
        />
        <ZoomImage
          className="ProductDetailsImage__zoom-image--desktop"
          alt={selectedImage.name}
          image={selectedImage}
          config={imageConfigurations(width)}
          errorImage="https://res.cloudinary.com/dovstvjim/image/upload/v1627933617/Propeller/product_false_kxyniy.jpg"
        />
        {showLightbox ? (
          <LightboxImage
            className="ProductDetailsImage__light-box--desktop"
            images={images}
            value={imageIndex}
            onClose={() => setShowLightbox(false)}
            onChange={setImageIndex}
            imageConfig={{
              alt: "Lightbox image preview",
              config: imageLightboxConfigurations(width)
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <Styles className="ProductDetailsImage">
      {renderDesktopNode()}
      {renderMobileNode()}
    </Styles>
  );
};

ProductDetailsImage.defaultProps = {};

export default ProductDetailsImage;
