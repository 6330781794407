import styled from "styled-components";

import { ProductMainStyledProps as Props } from "./ProductMain.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ProductMainStyled = styled.div<Props>`
  .ProductMain {
    &__content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 6rem;
      padding-top: 2.4rem;
      justify-content: center;

      @media (max-width: ${tablet}px) {
        justify-content: center;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 4rem;
        width: 100%;
      }
    }

    &__wishlist-heart {
      padding: 0;
    }

    &__icons {
      display: flex;
    }

    &__preview-image {
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: flex-start;
      margin-bottom: 1.6rem;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: none;
      }

      & .ProductDetailsImage {
        position: sticky;
        top: 8rem;
      }
    }

    &__form {
      flex: 1 0.3 0;
      min-width: 38rem;
      padding-top: 6.4rem;

      @media (max-width: ${tablet}px) {
        min-width: 28rem;
        padding-left: 0;
      }

      @media (max-width: ${tablet - 1}px) {
        padding: 0;
      }

      @media (max-width: ${mobile}px) {
        width: 100%;
        min-width: auto;
      }
    }

    &__baseInfo-icons {
      display: ${props => (props.isModal ? "none" : "flex")};

      .ProductModal {
        &__share {
          margin-left: 1.6rem;

          .Clickable {
            padding: 0;
          }
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @media (max-width: ${tablet - 1}px) {
        display: none;
      }
    }

    &__description {
      &__text {
        margin-top: 1.6rem;
        font-size: 1.6rem;
        color: var(--palette-white);
        padding: 2.4rem 0;
        font-family: inherit;
        white-space: pre-wrap;
        font-weight: 600;
        background-color: var(--palette-mid-blue-s15-l8);

        @media (max-width: ${tablet}px) {
          margin-top: 0;
        }
      }

      &__select {
        color: var(--palette-gray-s0-l70);
        font-weight: 400;
        font-size: 1.4rem;
        padding-top: 0.8rem;
      }
    }

    &__comment-box {
      padding-top: 4.8rem;
    }

    &__wishlist-button {
      margin-left: auto;

      path {
        fill: var(--palette-primary);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      background-color: var(--palette-dark-blue-s13-l11);
      justify-content: space-between;
      height: auto;
      border: none;
      border-radius: 1.6rem;
      position: relative;

      @media (max-width: ${tablet}px) {
        position: fixed;
        display: flex;
        justify-content: space-around;
        bottom: 0;
        left: 0;
        padding: 0.8rem;
        z-index: 3;
        align-items: center;
      }
    }

    &__advisement {
      position: absolute;
      width: 48%;
      left: 1.2rem;
      top: -0.8rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__addToCart {
      .Button {
        padding: 1.2rem 3.6rem;

        @media (max-width: ${mobile - 100}px) {
          padding: 1.2rem 1.6rem;
        }
      }

      @media (max-width: ${tablet}px) {
        flex-wrap: wrap;
        display: flex;
        align-self: center;
        flex: 0.5;
      }

      @media (max-width: ${tablet - 1}px) {
        flex: none;
      }
    }

    &__counter {
      margin-right: 3.2rem;

      @media (max-width: ${tablet}px) {
        display: flex;
        align-self: center;
        order: -1;
      }

      @media (max-width: ${tablet - 1}px) {
        margin-right: 2.4rem;
      }

      @media (max-width: ${mobile}px) {
        margin-right: 0;
      }
    }
  }
`;

export default ProductMainStyled;
