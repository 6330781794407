import styled from "styled-components";

import { AddToWishlistButtonStyledProps as Props } from "./AddToWishlistButton.types";
import Button from "components/global/Button/Button";

const AddToWishlistButtonStyled = styled(Button)<Props>`
  &.AddToWishlistButton {
    padding: 1.2rem 1.8rem;
  }

  .AddToWishlistButton {
    &__text {
      padding-left: 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-text-primary);
    }
  }
`;

export default AddToWishlistButtonStyled;
