// FilterAccordion helper functions and data

import { Product } from "artisn/types";
import { FormatByCurrencyOptions } from "artisn-ui-react";

import { FilterOptions, PriceOption } from "./FiltersAccordion.types";
import { PriceRange } from "./FiltersAccordion.types";

export const filterNumber = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const currencyOptionsDefault = (
  currency: string,
  minimumFractionDigits: number
): FormatByCurrencyOptions => {
  return {
    currency,
    minimumFractionDigits
  };
};

export const filterOptionsDefault = (): FilterOptions => {
  return {
    POINTS: {
      min: 0,
      max: 0
    },
    NORMAL: {
      min: 0,
      max: 0
    }
  };
};

export const filterOptions = {
  POINTS: {
    min: 0,
    max: 0
  },
  NORMAL: {
    min: 0,
    max: 0
  }
};

export const getProductsPrice = (
  products: Product[] | undefined,
  priceOption: PriceOption,
  priceRange: PriceRange
) => {
  const productsPrice = products?.map(product => {
    const { prices } = product;
    const { NORMAL, POINTS } = prices;
    const enhancedPoints = POINTS?.netPrice ? POINTS?.netPrice : 0;
    const enhancedNormal = NORMAL?.netPrice ? NORMAL?.netPrice : 0;
    return priceOption === "NORMAL" ? enhancedNormal : enhancedPoints;
  });

  if (!productsPrice?.length) return 0;
  return priceRange === "MAX"
    ? Math.max(...productsPrice)
    : Math.min(...productsPrice);
};
