import { Benefit } from "artisn/types";
import { ButtonGroup } from "artisn-ui-react";
import EmptyCouponSVG from "images/empty-coupon.svg";
import React, { useState } from "react";

import Styles from "./BenefitsList.styles";
import { BenefitsListProps as Props } from "./BenefitsList.types";
import CouponDetail from "../CouponDetail/CouponDetail";
import EmptyState from "components/global/EmptyState/EmptyState";
import useI18n from "hooks/useI18n";

const { Button } = ButtonGroup;

const BenefitsList: React.FC<Props> = props => {
  const { benefits, showRemove = true, inCart = false } = props;
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>();
  const empty = benefits && benefits.length === 0;
  const t = useI18n();

  const emptyNode = (
    <EmptyState
      icon={<EmptyCouponSVG />}
      title={t.coupons.empty}
      description={t.coupons.emptyDescription}
    />
  );

  const changeHandler = (benefitId: number) => {
    if (!benefits) return;
    const benefit = benefits.find(item => item.benefitId === benefitId);
    setSelectedBenefit?.(benefit);
  };

  return (
    <Styles className="BenefitsList">
      {empty ? emptyNode : null}
      {benefits?.map((item, index) => {
        const { benefitId } = item;
        const selected = selectedBenefit?.benefitId === benefitId;
        return (
          <Button
            disabled={inCart}
            className="BenefitsList__button"
            value={benefitId}
            type="LINK"
            id={benefitId.toString()}
            key={index}
            onClick={() => changeHandler(benefitId)}
          >
            <CouponDetail
              key={index}
              inCart={inCart}
              showRemove={showRemove}
              benefit={item}
              selected={selected}
            />
          </Button>
        );
      })}
    </Styles>
  );
};

BenefitsList.defaultProps = {};

export default BenefitsList;
