import styled from "styled-components";

import { ModalHeaderStyledProps } from "./ProductModal.types";
import { ModalIconStyledProps } from "./ProductModal.types";
import { ProductModalStyledProps as Props } from "./ProductModal.types";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ProductModalStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 96rem);
  height: min(${getMaxHeight()}, 58.8rem);
  background-color: unset !important ;

  @media (max-width: ${tablet}px) {
    max-height: calc(
      80vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
    min-width: unset;
    height: 66%;
  }

  @media (max-width: ${tablet - 1}px) {
    max-height: calc(
      100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
    min-width: unset;
    height: 100%;
  }

  .Modal__body {
    border-radius: 1.6rem;
    background-color: var(--palette-dark-blue-s13-l11);

    &::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar {
      width: 0;
    }

    @media (max-width: ${tablet - 1}px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ProductError {
        &__error-state {
          &__title {
            text-align: center;
          }
        }
      }
    }
  }

  .ProductModal {
    &__main {
      width: 100%;
    }
  }

  && .ProductMain {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "col col";
      padding: unset;

      @media (max-width: ${tablet - 1}px) {
        grid-template-columns: 1fr;
        grid-template-areas: "col";
        overflow: hidden;
        overflow-y: auto;
      }
    }

    &__preview-image {
      .ProductDetailsImage {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 5.2rem;

        &__thumbnail-carousel {
          display: none;
        }

        &__zoom-image {
          &--desktop {
            display: none;
          }
        }

        &__image {
          &--tablet {
            display: none;
          }

          &--mobile {
            display: block;
          }
        }

        &__thumbnail-carousel {
          &--mobile {
            margin: 0.8rem;
            display: flex;
          }
        }
      }
    }

    &__form {
      overflow: hidden;
      overflow-y: auto;
      max-height: 49.6rem;
      border-left: 0.1rem solid var(--palette-gray-s0-l20);

      @media (max-width: ${tablet}px) {
        margin-bottom: 7.2rem;
        max-height: unset;
      }

      .Pocket {
        &__header {
          &__title {
            &:first-child {
              font-weight: 600;
              font-size: 1.6rem;
              padding: 2.4rem 1.6rem;

              @media (max-width: ${tablet - 1}px) {
                padding: 1.6rem;
              }
            }
          }
        }
      }

      .ProductBaseInfo {
        &__name {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }
    }

    &__description {
      &__text {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }

    &__comment-box {
      padding-bottom: 1.2rem;
    }

    &__actions,
    &__wish-button {
      display: flex;
      position: fixed;
      min-width: 100%;
      bottom: 0;
      padding: 1.2rem;
      left: 0;

      .ProductMain {
        &__wishlist-button {
          margin-left: 0;
        }
      }
    }

    &__actions {
      right: 0;
      left: auto;
      justify-content: flex-end;

      @media (max-width: ${tablet - 1}px) {
        position: fixed;
        display: flex;
        justify-content: space-between;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.2rem 1.6rem;
        align-items: center;
      }

      @media (max-width: ${mobile - 1}px) {
        padding: 1.2rem 0.8rem;
      }
    }

    &__addToCart {
      @media (max-width: ${tablet}px) {
        .AddToCartButton {
          &__btn {
            &__no-ok {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ProductPlaceholder {
    @media (max-width: ${tablet}px) {
      padding: 0;
    }
  }
`;

export const ModalHeaderStyled = styled.div<ModalHeaderStyledProps>`
  .ProductModal {
    &__header {
      padding: 2.4rem 3.2rem;
      font-size: 2rem;
      font-weight: 600;

      @media (max-width: ${tablet}px) {
        padding: 2.4rem;
        font-size: 1.6rem;
      }
    }
  }
`;

export const ModalIconStyled = styled.div<ModalIconStyledProps>`
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${tablet}px) {
    padding: 1.2rem 0.4rem;
  }
`;

export default ProductModalStyled;
