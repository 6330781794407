// SEO utility functions
import { Benefit, Category, Product } from "artisn/types";
import router from "next/router";
import slugify from "slugify";

const slugifyConfig = {
  lower: true,
  locale: "en",
  strict: true
};

export const sanitizeText = (text: string) => slugify(text, slugifyConfig);

export const goToProductDetail = (product: Product) => {
  const { productId, name } = product;
  const sanitizedName = sanitizeText(name);
  router.push(`/products/${productId}/${sanitizedName}`);
};

export const goToBenefitDetail = (benefit: Benefit) => {
  const { award } = benefit;
  if (benefit.type === "PRODUCT" && Array.isArray(award)) {
    const { productId, productDescription } = award[0];
    const sanitizedName = sanitizeText(productDescription);
    router.push(`/products/${productId}/${sanitizedName}`);
  }
};

export const sanitizeCategoryPath = (category: Category) => {
  const { categoryId, name } = category;
  const sanitizedName = sanitizeText(name);
  return `/categories/${categoryId}/${sanitizedName}`;
};
