// useUseOrders customHook
import { fetchIncompleteOrders, fetchOrderDetails } from "@multicines/services";
import { useCataloguesStore, useVendorsStore } from "@multicines/stores";
import { useCallback, useMemo } from "react";

const useOrders = () => {
  const selectedVendorId = useVendorsStore(store => store.selectedVendorId);
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );

  const getPendingOrderDetail = useCallback(async () => {
    const incompleteOrders = await fetchIncompleteOrders(
      selectedCatalogueId,
      selectedVendorId
    );
    if (!incompleteOrders.length) return;
    const [firstIncompleteOrder] = incompleteOrders;
    const { id: orderId } = firstIncompleteOrder ?? {};
    if (!orderId) throw new Error("Incomplete order id is undefined");
    return fetchOrderDetails(orderId);
  }, [selectedCatalogueId, selectedVendorId]);

  return useMemo(() => ({ getPendingOrderDetail }), [getPendingOrderDetail]);
};

export default useOrders;
