// AddToCartButton helper functions and data

import { AddToCartConfig } from "./AddToCartButton.types";

export const defaultConfig: AddToCartConfig = {
  amount: 1,
  comment: ""
};

export const addToCartField = ["amount", "questionAndAnswers"];
