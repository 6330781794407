import styled from "styled-components";

import { CouponDetailStyledProps as Props } from "./CouponDetail.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const CouponDetailStyled = styled.div<Props>`
  display: flex;
  margin-bottom: 1.6rem;
  flex-direction: column;
  flex: 1;

  .CouponDetail {
    padding: 0 1.6rem;

    &__container {
      display: flex;
      justify-content: space-between;
      min-height: 10.4rem;
      background-color: var(--palette-mid-blue-s15-l20);
      border-radius: 1.6rem;
      padding: 1.6rem;

      @media (max-width: ${mobile}px) {
        padding: 1.6rem;
      }
    }

    &__left {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: space-between;

      &__icon {
        min-width: 2.4rem;
        min-height: 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l40);
        border-radius: 10rem;
        margin-right: 1.2rem;
      }

      &__label {
        &__title {
          font-size: 1.6rem;
          color: ${props =>
            props.selected ? "var(--palette-primary)" : "var(--palette-white)"};
          font-weight: 700;
        }

        &__description {
          font-size: 1.6rem;
          color: ${props =>
            props.selected
              ? "var(--palette-white)"
              : "var(--palette-gray-s0-l70)"};
          font-weight: 400;
          padding-top: 0.8rem;
        }

        &__expiration-date {
          font-size: 1.4rem;
          color: var(--palette-gray-s0-l70);
          font-weight: 600;
          padding-top: 1.6rem;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
    }

    &__remove {
      padding: 0;
    }

    &__remove-error {
      font-size: 1.2rem;
      color: var(--palette-red-s65-l60);
      padding-top: 0.4rem;
    }
  }
`;

export default CouponDetailStyled;
