import styled from "styled-components";

import { ProductDetailsImageStyledProps as Props } from "./ProductDetailsImage.types";
import CONSTANTS from "config/constants";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const ProductDetailsImageStyled = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .ProductDetailsImage {
    &__thumbnail-carousel {
      padding-right: 1.6rem;

      @media (max-width: ${mobile}px) {
        padding-right: 0;
        padding-top: 1.6rem;
      }

      & .ThumbnailCarousel__slider {
        justify-content: center;
      }

      &--desktop {
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${mobile + 1}px) {
          display: none;
        }
      }
    }

    &__text {
      place-self: flex-start;
      padding: 2.4rem;

      &__name {
        color: var(--palette-white);
        font-weight: 700;
        font-size: 2.4rem;
      }

      &__description {
        color: var(--palette-white);
        font-size: 1.6rem;
        padding-top: 0.8rem;
      }

      &__advisement {
        margin-top: 1.6rem;
        margin-bottom: 0.4rem;

        @media (width > ${tablet}px) {
          display: none;
        }
      }
    }

    &__image {
      &--tablet {
        @media (min-width: ${tablet + 1}px) {
          display: none;
        }
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        min-width: 100%;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        border-top-right-radius: 0;

        .Image {
          &__img {
            min-width: 100%;
            object-fit: cover;
          }
        }

        @media (min-width: ${mobile + 1}px) {
          display: none;
        }
      }
    }

    &__zoom-image {
      &--desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__light-box {
      &--desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }
  }
`;

export default ProductDetailsImageStyled;
