import { useProductsStore, useShoppingCartStore } from "@multicines/stores";
import { getShoppingCartProductsByVendor } from "@multicines/utils";
import { SNACKS_VENDOR } from "@multicines/utils";
import { events } from "artisn/analytics";
import { removeProduct, setProduct } from "artisn/shopping-cart";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";

import Styles from "./CartProducts.styles";
import { CartProductsProps as Props } from "./CartProducts.types";
import RecommendedSnacksList from "./RecommendedSnacksList/RecommendedSnacksList";
import ShoppingCartProduct from "../ShoppingCartProduct/ShoppingCartProduct";
import Button from "components/global/Button/Button";
import ProductModal from "components/global/ProductModal/ProductModal";
import { getAuth } from "config/artisn.config";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { sortByProductDate } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { goToProductDetail } from "utils/seo.utils";

const { logRemoveProductFromCart } = events.shoppingCart;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;
const { ACCOUNT_ID } = CONSTANTS.ARTISN;

const CartProducts: React.FC<Props> = props => {
  const { disabled, className, showDetails } = props;
  const queryClient = useQueryClient();
  const router = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { isTalkShop, navigateWithTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isSnackWorkflow: true,
    isTalkShop
  });
  const t = useI18n();
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const showModifiers = useShoppingCartStore(store => store.showModifiers);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const setSelectedProduct = useProductsStore(
    store => store.setSelectedProduct
  );
  const setSnackRestrictionCard = useProductsStore(
    store => store.setSnackRestrictionCard
  );
  const selectedProduct = useProductsStore(store => store.selectedProduct);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { commonParams, logExploreSnacksCatalogue } = analyticsHook;

  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const { name: shoppingCartName, id: shoppingCartId } = shoppingCart ?? {};

  const productId = selectedProduct ? selectedProduct.productId : "";

  const [, snacksStore] = selectedStore ?? [];

  const { vendor } = snacksStore ?? {};
  const { id: selectedSnackVendorId } = vendor ?? {};

  const products = useMemo(
    () =>
      getShoppingCartProductsByVendor(
        shoppingCart,
        selectedSnackVendorId ?? SNACKS_VENDOR.id
      ),
    [selectedSnackVendorId, shoppingCart]
  );
  const snacksCount = products?.length;
  const buttonText = snacksCount ? t.cart.exploreSnacks : t.cart.addSnacks;
  const sortedProducts = useMemo(
    () => products?.sort(sortByProductDate),
    [products]
  );

  const deleteProductHandler = async (product: CartProduct) => {
    try {
      if (!snacksStore) return;

      const { additionalInfo } = product ?? {};
      const { specialTicketType } = additionalInfo ?? {};

      if (specialTicketType) {
        setSnackRestrictionCard(undefined);
      }

      const editedShoppingCart = await removeProduct(product, {
        anonymous: isAnonymous!,
        accountId: ACCOUNT_ID,
        customerId: uid!,
        store: snacksStore
      });
      if (editedShoppingCart) {
        const productsCount =
          getShoppingCartProducts(editedShoppingCart).length;
        if (!productsCount) {
          await getAuth.currentUser?.getIdToken(true);
          await queryClient.invalidateQueries([uid, "loyalty"]);
        }
      }
      if (!shoppingCartId) return;

      logRemoveProductFromCart({
        cartId: shoppingCartId,
        product,
        store: snacksStore,
        ...commonParams
      });
    } catch (e) {
      notify(e, "Delete Product Handler - Cart products");
      createErrorNotification(e.message);
    }
  };

  const onClickButtonCategories = () => {
    logExploreSnacksCatalogue();
    navigateWithTalkShop("/categories");
  };

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const isPageProductDetail = () => {
    const pathname = router.pathname.split("/").slice(1);
    const isCorrectPage =
      pathname.length === 3 && pathname[2] === "[productName]";

    return isCorrectPage;
  };

  const onClickProduct = (product: CartProduct) => {
    setSelectedProduct(product);
    if (WITH_PRODUCT_MODAL && !isPageProductDetail()) {
      onClickModal();
    } else {
      goToProductDetail(product);
    }
  };

  const onChangeQuantity = (amount: number, product: CartProduct) => {
    if (!snacksStore) return;

    setProduct(product, {
      amount,
      shoppingCartName,
      store: snacksStore,
      anonymous: isAnonymous!,
      accountId: ACCOUNT_ID,
      customerId: uid!
    });
  };

  const showInTalkShop = isTalkShop ? !!snacksCount : true;
  const showSnacksButton = !showDetails && showInTalkShop;

  return (
    <Styles className={`CartProducts ${className}`}>
      {showInTalkShop ? (
        <p className="CartProducts__title">{t.cart.snacks}</p>
      ) : null}
      {sortedProducts?.map((product, index) => {
        const { amount, productId, benefitId } = product;
        const isBenefitProduct =
          selectedBenefit && benefitId === selectedBenefit.benefitId;
        const modifiers = showModifiers(product);
        return (
          <ShoppingCartProduct
            product={product}
            initialQuantity={amount}
            onChangeQuantity={(amount: number) =>
              onChangeQuantity(amount, product)
            }
            onDelete={() => deleteProductHandler(product)}
            onClick={() => onClickProduct(product)}
            key={`${productId}-${index}`}
            disabled={disabled || isBenefitProduct}
            modifiers={modifiers}
            showDetails={showDetails}
          />
        );
      })}
      {showSnacksButton ? (
        <>
          <RecommendedSnacksList />
          <Button
            className="CartProducts__button"
            onClick={onClickButtonCategories}
          >
            {buttonText}
          </Button>
        </>
      ) : null}
      <ProductModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        productId={productId}
      />
    </Styles>
  );
};

export default CartProducts;
