import { useFetchProductDetails } from "@multicines/services";
import { useCataloguesStore } from "@multicines/stores";
import { useProductsStore } from "@multicines/stores";
import { useVendorsStore } from "@multicines/stores";
import { getApiMessageError, getTradeCodeApiError } from "@multicines/utils";
import CloseSVG from "images/close.svg";
import React from "react";

import Styles from "./ProductModal.styles";
import { ModalIconStyled } from "./ProductModal.styles";
import { ProductModalProps as Props } from "./ProductModal.types";
import ErrorState from "../ErrorState/ErrorState";
import ProductMain from "components/product/ProductMain/ProductMain";
import CONSTANTS from "config/constants";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { dismissAddToCartNotification } from "utils/notifications.utils";

const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const ProductModal: React.FC<Props> = props => {
  const { className, isOpen, onClose, productId, onOpenDrawer } = props;
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [, snacksStore] = selectedStore ?? [];
  const { storeId } = snacksStore ?? {};
  const selectedVendorId = useVendorsStore(store => store.selectedVendorId);
  const productsDetailsResponse = useFetchProductDetails(productId, {
    catalogueId: selectedCatalogueId,
    storeId: storeId ?? 0,
    vendorId: selectedVendorId,
    withCategories: true
  });
  const selectedProduct = useProductsStore(store => store.selectedProduct);
  const isAnonymous = useAuthStore(state => state.isAnonymous);

  const { data: productData, isLoading } = productsDetailsResponse;
  const { isError, error } = productsDetailsResponse;
  const product = selectedProduct ?? productData;

  const showShoppingCartDrawer = () => {
    if (onOpenDrawer) onOpenDrawer();
    dismissAddToCartNotification();
  };

  const modalIconNode = (
    <ModalIconStyled>
      <CloseSVG />
    </ModalIconStyled>
  );

  return (
    <Styles
      className={`ProductModal ${className}`}
      closeIcon={modalIconNode}
      opened={isOpen}
      onClose={onClose}
      backdropConfig={{ opacity: 0.6, className: "modalBackdrop" }}
    >
      <ProductMain
        className="ProductModal__main"
        productId={productId}
        isLoading={isLoading}
        isError={isError}
        isSignedIn={!isAnonymous}
        product={product}
        isModal={WITH_PRODUCT_MODAL}
        showShoppingCartDrawer={showShoppingCartDrawer}
        onCloseModal={onClose}
      />
      {isError ? (
        <ErrorState
          title={getTradeCodeApiError(error, "TPD")}
          description={getApiMessageError(error)}
        />
      ) : null}
    </Styles>
  );
};

ProductModal.defaultProps = {
  className: ""
};

export default ProductModal;
