import { useFetchIncompleteOrders } from "@multicines/services";
import { useFetchRecommendedProducts } from "@multicines/services";
import { usePutCancelOrder } from "@multicines/services";
import { useShoppingCartStore } from "@multicines/stores";
import { useCountriesStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { ValidateShoppingCartResult } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { emptyShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartProducts, removeBenefit } from "artisn/shopping-cart";
import { formatByCurrency, ShareButton } from "artisn-ui-react";
import CloseSVG from "images/close.svg";
import PlusSVG from "images/plus.svg";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

import Styles from "./ShoppingCartDrawer.styles";
import { ShoppingCartDrawerProps as Props } from "./ShoppingCartDrawer.types";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import CartProducts from "components/Cart/CartProducts/CartProducts";
import EmptyCart from "components/Cart/EmptyCart/EmptyCart";
import RedeemCoupon from "components/Cart/RedeemCoupon/RedeemCoupon";
import { currencyOptionsDefault } from "components/categories/FiltersAccordion/FilterAccordion.helpers";
import ShoppingCartSummary from "components/checkout/ShoppingCartSummary/ShoppingCartSummary";
import CouponDetail from "components/coupons/CouponDetail/CouponDetail";
import Button from "components/global/Button/Button";
import UpsaleModal from "components/global/UpsaleModal/UpsaleModal";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useShippingCost from "hooks/useShippingCost";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { getFullPath } from "utils/common.utils";

const { ARTISN, FEATURE_FLAGS, API } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { API_URL } = API;
const { logClearCart } = events.shoppingCart;
const { logViewCart } = events.shoppingCart;
const { WITH_COUPONS, WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_UPSALE_MODAL, WITH_PURCHASE } = FEATURE_FLAGS;

const ShoppingCartDrawer: React.FC<Props> = props => {
  const router = useRouter();
  const { onClose, opened } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const cancelQuery = usePutCancelOrder({ isAnonymous, uid });
  const { data: recommendedProducts } = useFetchRecommendedProducts({
    isAnonymous,
    uid
  });
  const { data: incompleteOrders } = useFetchIncompleteOrders({
    isAnonymous,
    uid
  });
  const shippingCost = useShippingCost();
  const selectedStore = useStoresStore(store => store.selectedStore);
  const selectedCountry = useCountriesStore(state => state.selectedCountry);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const setTemporalBenefit = useShoppingCartStore(
    store => store.setTemporalBenefit
  );
  const [errors, setErrors] = useState<ValidateShoppingCartResult>();
  const [disabled, setDisabled] = useState(false);
  const viewCartLogged = useRef(false);
  const [removeBenefitError, setRemoveBenefitError] = useState("");
  const [openUpsale, setOpenUpsale] = useState(WITH_UPSALE_MODAL);

  const { canBeSendAnalytics, commonParams } = analyticsHook;

  const { mutate: cancelOrder, reset: resetCancelOrder } = cancelQuery;
  const { currency } = selectedCountry;

  const products = shoppingCart
    ? getShoppingCartProducts(shoppingCart)
    : undefined;
  const totals = shoppingCart ? getShoppingCartTotal(shoppingCart) : undefined;
  const [ticketsStore] = selectedStore ?? [];
  const { storeId } = ticketsStore ?? {};

  const empty = (products && products.length === 0) || !shoppingCart;
  const { id: shoppingCartId, benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;

  const closeHandler = () => {
    onClose();
    setOpenUpsale(true);
  };

  const removeHandler = async () => {
    if (!selectedBenefit || !ticketsStore) return;

    try {
      setRemoveBenefitError("");
      const { benefitId } = selectedBenefit;

      const product = products?.find(
        product => product.benefitId === benefitId
      );
      // TODO: SDK
      await removeBenefit({
        benefitId,
        shippingCost,
        product,
        productConfig: {
          accountId: ACCOUNT_ID,
          customerId: uid!,
          anonymous: isAnonymous!,
          store: ticketsStore
        },
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous!,
        apiURL: API_URL,
        accountId: ACCOUNT_ID,
        customerId: uid!
      });
      setTemporalBenefit(undefined);
    } catch (e) {
      notify(e, "removeHandler - ShoppingCartDrawer");
      setRemoveBenefitError(e.message);
    }
  };

  const emptyCartHandler = () => {
    if (!shoppingCartId) return;

    logClearCart({
      cartId: shoppingCartId,
      ...commonParams
    });

    emptyShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous!,
      accountId: ACCOUNT_ID,
      customerId: uid!
    });
    setTemporalBenefit(undefined);
  };

  useEffect(() => {
    if (!storeId) return;
    if (!products?.length || errors) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [errors, products?.length, storeId]);

  useEffect(() => {
    if (!shoppingCartId || viewCartLogged.current || !products?.length) {
      return;
    }
    if (!canBeSendAnalytics) return;

    logViewCart({
      cartId: shoppingCartId,
      products,
      ...commonParams
    });

    viewCartLogged.current = true;
  }, [products, shoppingCartId, canBeSendAnalytics, commonParams]);

  useEffect(() => {
    if (!incompleteOrders || !incompleteOrders.length) return;
    return () => {
      for (const incompleteOrder of incompleteOrders) {
        const { id } = incompleteOrder;
        cancelOrder(id);
        resetCancelOrder();
      }
    };
  }, [cancelOrder, incompleteOrders, resetCancelOrder]);

  return (
    <Styles
      className="ShoppingCartDrawer"
      opened={opened}
      onClose={closeHandler}
    >
      <CloseSVG className="ShoppingCartDrawer__close" onClick={closeHandler} />
      <div className="ShoppingCartDrawer__title">
        Carrito
        {WITH_SHARE_SHOPPING_CART && !empty ? (
          <ShareButton
            className="ShoppingCartDrawer__share"
            config={{
              title: `Compartir carrito`,
              text: `Comparte tu carrito con otra persona`,
              url: `${getFullPath()}?shareId=${uid}`
            }}
          />
        ) : null}
      </div>
      {empty ? (
        <EmptyCart />
      ) : (
        <>
          <div className="ShoppingCartDrawer__cart">
            {WITH_COUPONS ? (
              <RedeemCoupon className="ShoppingCartDrawer__coupon" />
            ) : null}
            {selectedBenefit ? (
              <CouponDetail
                inCart
                removeError={removeBenefitError}
                benefit={selectedBenefit}
                onRemove={removeHandler}
              />
            ) : null}
            <div className="ShoppingCartDrawer__order">
              <p className="ShoppingCartDrawer__order__title">Tu orden</p>
              <p className="ShoppingCartDrawer__order__total">
                {totals
                  ? `${formatByCurrency(
                      totals.total,
                      currencyOptionsDefault(currency, 0)
                    )}`
                  : "$0.00"}
              </p>
            </div>
            <Button
              mode="LINK"
              className="ShoppingCartDrawer__empty-button"
              onClick={emptyCartHandler}
            >
              Vaciar carrito
            </Button>
            <CartProducts disabled={disabled} />
            <Button
              mode="LINK"
              className="ShoppingCartDrawer__add-button"
              onClick={() => router.push("/categories")}
            >
              <PlusSVG /> Continuar agregando al carrito
            </Button>
            <ShoppingCartSummary className="ShoppingCartDrawer__summary" />
          </div>
          <div className="ShoppingCartDrawer__pay-button">
            <CartPayButton
              empty={empty}
              setDisabled={setDisabled}
              shoppingCart={shoppingCart}
              setErrors={setErrors}
            />
          </div>
        </>
      )}
      {recommendedProducts?.length && WITH_PURCHASE ? (
        <UpsaleModal
          opened={openUpsale}
          onSuccess={() => setOpenUpsale(false)}
          products={recommendedProducts}
          onClose={() => setOpenUpsale(false)}
        />
      ) : null}
    </Styles>
  );
};

export default ShoppingCartDrawer;
