// useOnUnmount customHook
import { useEffect } from "react";
import { useRef } from "react";

import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

const useOnUnmount = (callback: () => void) => {
  const unmounted = useRef(false);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useIsomorphicLayoutEffect(() => {
    if (unmounted.current) return;

    return () => {
      unmounted.current = true;
      callbackRef.current();
    };
  }, []);
};

export default useOnUnmount;
