import styled from "styled-components";

import { ModifiersFormStyledProps as Props } from "./ModifiersForm.types";

const ModifiersFormStyled = styled.div<Props>`
  .ModifiersForm {
  }
`;

export default ModifiersFormStyled;
