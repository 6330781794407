import { TextInput } from "artisn-ui-react";
import React from "react";

import Styles from "./RedeemCouponInput.styles";
import { RedeemCouponInputProps as Props } from "./RedeemCouponInput.types";
import Button from "components/global/Button/Button";
import useI18n from "hooks/useI18n";

const RedeemCouponInput: React.FC<Props> = props => {
  const { className, disabled, couponCode, onRedeem, setCouponCode } = props;
  const t = useI18n();

  return (
    <Styles className={`RedeemCouponInput ${className}`}>
      <TextInput
        className="RedeemCouponInput__text-input field"
        placeholder={t.coupons.enterCoupon}
        name="redeem"
        label={t.coupons.haveCoupon}
        disabled={disabled}
        value={couponCode}
        onChange={e => setCouponCode(e.target.value.trim())}
      />
      <Button
        className="RedeemCouponInput__input-button"
        disabled={disabled}
        onClick={() => onRedeem(couponCode)}
      >
        {t.common.apply}
      </Button>
    </Styles>
  );
};

RedeemCouponInput.defaultProps = {
  className: ""
};

export default RedeemCouponInput;
