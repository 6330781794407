import styled from "styled-components";

import { CartProductsStyledProps as Props } from "./CartProducts.types";
import CONSTANTS from "config/constants";

const { desktop } = CONSTANTS.BREAKPOINTS;
const CartProductsStyled = styled.div<Props>`
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .CartProducts {
    &__title {
      color: var(--palette-light-blue-s10-l60);
      text-align: start;
      font-size: 2rem;
      font-weight: 600;
      padding: 1.6rem 0.8rem;
    }

    &__button {
      background-color: var(--palette-mid-blue-s15-l20);
      border: none;
      margin-top: 1.6rem;
      color: var(--palette-primary);
      font-weight: 700;
      font-size: 1.6rem;
      padding: 1.6rem;
      width: 50%;
      align-self: center;
      box-shadow: none;
      border-radius: 1.2rem;

      &:hover:not([disabled]) {
        background-color: var(--palette-mid-blue-s15-l20);
        box-shadow: 0rem 0rem 0rem var(--palette-gray-s0-l95),
          0rem 0rem 0.4rem var(--palette-mid-blue-s15-l20);
        border: none;
      }

      @media (max-width: ${desktop}px) {
        width: 100%;
      }
    }
  }
`;

export default CartProductsStyled;
