import styled from "styled-components";

import { CartButtonStyledProps as Props } from "./CartButton.types";
import CONSTANTS from "config/constants";

const { WITH_WISHLIST } = CONSTANTS.FEATURE_FLAGS;

const CartButtonStyled = styled.div<Props>`
  cursor: pointer;

  .CartButton {
    &--heart {
      display: none;

      path {
        fill: var(--palette-primary);
      }

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST && props.isSignedIn
            ? "block"
            : "none"};
      }
    }

    &--cart {
      display: block;
      padding: 0.8rem 1.2rem;
      border-radius: 1.2rem;

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST ? "none" : "block"};
      }

      & path {
        fill: var(--palette-white);
      }
    }

    &__filled {
      background-color: var(--palette-primary);

      & path {
        fill: var(--palette-mid-blue-s15-l20);
      }
    }

    &__disabled {
      background-color: transparent;
      border: 0.2rem solid var(--palette-gray-s0-l40-a40);
    }
  }
`;

export default CartButtonStyled;
