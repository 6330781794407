import styled from "styled-components";

import { EmptyCartStyledProps as Props } from "./EmptyCart.types";

const EmptyCartStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 35.6rem;
  padding: 6.4rem 0;

  .EmptyCart {
    &__icon {
      width: 12.8rem;
      height: 12.8rem;
      background-color: var(--palette-gray-s0-l98);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      padding-top: 2rem;
      padding-bottom: 1.2rem;
      font-size: 2.4rem;
      text-align: center;
    }

    &__description {
      padding-bottom: 4rem;
      font-size: 1.6rem;
      color: var(--palette-gray-s0-l40);
      text-align: center;
    }

    &__button {
      padding: 1.6rem 2rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;

export default EmptyCartStyled;
