import { getPricesByCategory, titleCase } from "@multicines/utils";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import Styles from "./ModifierPrices.styles";
import { ModifierPricesProps as Props } from "./ModifierPrices.types";

const ModifierPrices: React.FC<Props> = props => {
  const { name, totals, prices } = props;
  const { netPrice } = totals;
  const price = getPricesByCategory(prices);

  return (
    <Styles className="ModifierPrices">
      <p className="ModifierPrices__text">{titleCase(name)}</p>
      {price && price > 0 ? (
        <p className="ModifierPrices__sum">
          (+{" "}
          {formatByCurrency(netPrice, {
            currency: "USD"
          })}
          )
        </p>
      ) : null}
    </Styles>
  );
};

ModifierPrices.defaultProps = {};

export default ModifierPrices;
