import React from "react";

import { ProductPlaceholderStyled as Styles } from "./Product.styles";
import { ProductPlaceholderProps as Props } from "./Product.types";

const ProductPlaceholder: React.FC<Props> = props => {
  const modifierNode = (
    <div className="ProductPlaceholder__wrap">
      <div className="ProductPlaceholder__wrap__group">
        <div className="ProductPlaceholder__modifier__name loading-shine" />
        <div className="ProductPlaceholder__modifier__description loading-shine" />
      </div>
      <div className="ProductPlaceholder__radio" />
    </div>
  );

  return (
    <Styles className="ProductPlaceholder">
      <div className="ProductPlaceholder__image__container">
        <div className="ProductPlaceholder__image loading-shine" />
        <div className="ProductPlaceholder__image__name loading-shine" />
        <div className="ProductPlaceholder__image__price loading-shine" />
      </div>
      <div className="ProductPlaceholder__form">
        <div className="ProductPlaceholder__wrap-header">
          <div className="ProductPlaceholder__wrap__left">
            <div className="ProductPlaceholder__name loading-shine" />
            <div className="ProductPlaceholder__price loading-shine" />
          </div>
          <div className="ProductPlaceholder__check" />
        </div>
        <div className="ProductPlaceholder__modifier">
          {modifierNode}
          <div className="ProductPlaceholder__line" />
          {modifierNode}
          <div className="ProductPlaceholder__line" />
          {modifierNode}
          <div className="ProductPlaceholder__line" />
          {modifierNode}
        </div>
      </div>
    </Styles>
  );
};

ProductPlaceholder.defaultProps = {};

export default ProductPlaceholder;
