import styled from "styled-components";

import { RedeemCouponInputStyledProps as Props } from "./RedeemCouponInput.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const RedeemCouponInputStyled = styled.div<Props>`
  position: relative;
  display: flex;
  width: 100%;

  .RedeemCouponInput {
    &__text-input {
      padding: 0.4rem 1.2rem;
      font-size: 1.6rem;
      color: var(--palette-light-blue-s10-l60);
      outline: none;

      @media screen and (max-width: ${tablet}px) {
        padding: 0;
      }

      .TextInput__input {
        border: none;
        line-height: 5.6rem;
        border-radius: 1.2rem;
        background-color: var(--palette-mid-blue-s15-l20);
        color: var(--palette-white);
      }
    }

    &__input-button {
      position: absolute;
      top: 1.4rem;
      right: 3.2rem;
      padding: 1.6rem 2rem;
      font-weight: 600;
      z-index: 1;
      font-size: 1.6rem;
      background-color: var(--palette-mid-blue-s15-l20);
      border: none;

      &:hover:not([disabled]) {
        background-color: var(--palette-mid-blue-s15-l20);
        border: none;
        box-shadow: none;
      }
    }
  }
`;

export default RedeemCouponInputStyled;
