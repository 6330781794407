import styled from "styled-components";

import { ModifierPricesStyledProps as Props } from "./ModifierPrices.types";

const ModifierPricesStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .ModifierPrices {
    &__sum {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l70);
      line-height: 1.6rem;
      padding-top: 0.4rem;
    }

    &__text {
      font-size: 1.6rem;
      color: var(--palette-white);
      line-height: 1.6rem;
      font-weight: 600;
    }
  }
`;

export default ModifierPricesStyled;
