import InfoSVG from "images/info-yellow.svg";
import React from "react";

import Styles from "./InfoMessage.styles";
import { InfoMessageProps as Props } from "./InfoMessage.types";

const InfoMessage: React.FC<Props> = props => {
  const { message, className } = props;

  return (
    <Styles className={`InfoMessage ${className}`}>
      <div className="InfoMessage__svg">
        <InfoSVG />
      </div>
      <p className="InfoMessage__text">{message}</p>
    </Styles>
  );
};

InfoMessage.defaultProps = {};

export default InfoMessage;
