import styled from "styled-components";

import { ProductStyledProps as Props } from "./Product.types";
import { ProductPlaceholderStyledProps as PlaceholderProps } from "./Product.types";
import CONSTANTS from "config/constants";

const { desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ProductStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  padding-top: ${props =>
    props.talkShop ? "1.6rem" : `var(--sizes-navbar-height)`};
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas:
    ".      header ."
    ".      main   ."
    "footer footer footer";

  @media (max-width: ${tablet}px) {
    padding-top: ${props =>
      props.talkShop
        ? `calc(var(--sizes-navbar-height-mobile) - env(safe-area-inset-top))`
        : `var(--sizes-navbar-height-mobile-without-search)`};
  }

  & .Pocket {
    &__header {
      padding: 0.8rem 0;
      grid-area: header;

      &__title {
        font-size: 1.6rem;
        color: var(--palette-black-s0-l10);
      }
    }
  }

  .Product {
    &__header {
      .Product__shareButton {
        position: relative;
        top: -0.1rem;

        .Clickable {
          padding: 0;
        }
      }
    }

    &__navbar {
      & .Navbar__title {
        color: var(--palette-white);
        min-width: 100%;
        padding-left: 1.6rem;
      }

      @media (max-width: ${tablet}px) {
        display: grid;
      }
    }

    &__main {
      grid-area: main;

      &__header {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__topActions {
      display: flex;
      align-items: center;
      width: 50%;
      justify-content: space-between;
      padding: 2.4rem 0;

      @media (max-width: 851px) {
        width: 100%;
      }

      .Product__shareButton {
        display: block;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__shareButton {
      display: flex;
      justify-content: flex-end;

      @media (min-width: ${tablet + 1}px) {
        display: none;
      }
    }

    &__gallery {
      padding-bottom: 1.6rem;
      border-bottom: 0.8rem solid var(--palette-gray-s0-l98);
      margin-bottom: 1.6rem;

      @media (min-width: ${tablet}px) {
        display: none;
      }
    }

    &__slider {
      padding-bottom: 14rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__wishlist-button {
      margin-left: auto;

      path {
        fill: var(--palette-primary);
      }
    }

    &__category-gallery {
      padding-bottom: 12rem;

      @media (max-width: ${tablet}px) {
        padding-bottom: 2.4rem;
      }
    }

    &__footer {
      grid-area: footer;

      @media (max-width: ${tablet}px) {
        padding-top: 3.2rem;
      }

      @media (max-width: ${tablet - 1}px) {
        display: none;
      }
    }
  }

  && .ProductBaseInfo {
    &__name {
      white-space: normal;
    }
  }

  && .ProductMain {
    @media (min-width: ${desktop}px) {
      &__form {
        padding: 1.6rem;
      }

      &__addToCart .Button {
        width: 24rem;
      }

      &__counter {
        margin-right: 1.6rem;
      }
    }

    @media (max-width: ${mobile}px) {
      &__counter {
        margin-right: 0.8rem;
      }
    }

    &__actions {
      background-color: var(--palette-mid-blue-s15-l8);
    }
  }

  && .ProductDetailsImage {
    @media (max-width: ${tablet - 1}px) {
      flex-direction: column;

      &__thumbnail-carousel--desktop {
        display: none;
      }

      &__text {
        padding: 2.4rem 0;
      }

      &__image--tablet {
        width: inherit;
      }
    }
  }

  && .ModifiersForm {
    @media (min-width: ${tablet}px) {
      margin-top: 2.4rem;
    }
  }
`;

export const ProductPlaceholderStyled = styled.div<PlaceholderProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  grid-area: col;

  @media (max-width: ${tablet - 1}px) {
    flex-direction: column;
    align-items: center;
  }

  .ProductPlaceholder {
    &__image {
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.4rem;
      width: 48rem;
      height: 48rem;
      position: relative;
      bottom: 0;

      @media (max-width: ${mobile}px) {
        width: 32rem;
        height: 32rem;
      }

      @media (min-width: ${mobile - 50}px) {
        width: 38rem;
        height: 40rem;
      }

      &__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 100%;

        @media (max-width: ${tablet}px) {
          width: auto;
          min-width: 0;
        }

        @media (max-width: ${tablet - 1}px) {
          min-height: auto;
        }
      }

      &__name {
        width: 50%;
        height: 2.4rem;
        background-color: var(--palette-gray-s0-l40-a40);
        margin-bottom: 0.8rem;
        margin-top: 2.4rem;
        border-radius: 0.8rem;
        margin-left: 2.4rem;

        @media (max-width: ${mobile - 1}px) {
          margin-left: 4.4rem;
        }
      }

      &__price {
        width: 25%;
        height: 1.8rem;
        background-color: var(--palette-gray-s0-l40-a40);
        margin-bottom: 1.6rem;
        border-radius: 0.8rem;
        margin-left: 2.4rem;

        @media (max-width: ${mobile - 1}px) {
          margin-left: 4.4rem;
        }
      }
    }

    &__form {
      flex: 1;
      padding-left: 0;

      @media (max-width: ${tablet}px) {
        width: -webkit-fill-available;
        min-width: 32rem;
        padding-left: 0;
      }

      @media (max-width: ${tablet - 1}px) {
        min-width: 28rem;
        padding-top: 0;
      }
    }

    &__wrap {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-header {
        background-color: var(--palette-mid-blue-s15-l8);
        display: flex;
        margin-top: 6.4rem;
        padding: 0.8rem 1.6rem;
        justify-content: space-between;
        align-items: center;

        @media (max-width: ${tablet - 1}px) {
          margin-top: 2.4rem;
        }
      }

      &__left {
        width: 32rem;

        @media (max-width: ${mobile}px) {
          padding-left: 4.8rem;
        }
      }

      &__group {
        display: flex;
        flex: 0.8;
        flex-direction: column;
        margin-left: 1.6rem;

        @media (max-width: ${mobile - 1}px) {
          margin-left: 12%;
        }
      }
    }

    &__line {
      border-bottom: 0.2rem solid var(--palette-gray-s0-l40-a40);
      margin-bottom: 2.4rem;
      width: 100%;
      align-self: center;
    }

    &__check {
      width: 4.8rem;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l40-a40);
      margin-bottom: 1.6rem;
      margin-top: 2.4rem;
      border-radius: 0.4rem;
      margin-right: 2rem;

      @media (max-width: ${mobile - 1}px) {
        margin-right: 12%;
      }
    }

    &__radio {
      width: 2.4rem;
      height: 2.4rem;
      background-color: var(--palette-gray-s0-l40-a40);
      margin-bottom: 1.6rem;
      border-radius: 0.8rem;
      margin-right: 4rem;

      @media (max-width: ${mobile - 1}px) {
        margin-right: 18%;
      }
    }

    &__name {
      width: 40%;
      height: 2.4rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.8rem;

      @media (max-width: ${tablet - 1}px) {
        width: 64%;
      }
    }

    &__price {
      width: 24%;
      height: 2.4rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.8rem;
      margin-top: 0.8rem;
    }

    &__description {
      width: 5.6rem;
      height: 1.5rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.4rem;
    }

    &__actions {
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
    }

    &__counter {
      width: 16%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.4rem;
      margin-right: 3.2rem;
    }

    &__button {
      width: 40%;
      height: 4rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.4rem;
    }

    &__modifier {
      margin-top: 3.2rem;
      display: flex;
      flex-direction: column;
      margin-left: 1.8rem;

      &__name {
        width: 32%;
        height: 2.4rem;
        background-color: var(--palette-gray-s0-l40-a40);
        margin-bottom: 1rem;
        border-radius: 0.6rem;

        @media (max-width: ${tablet - 1}px) {
          width: 40%;
        }
      }

      &__description {
        width: 50%;
        height: 1.6rem;
        background-color: var(--palette-gray-s0-l40-a40);
        margin-bottom: 2.4rem;
        border-radius: 0.6rem;

        @media (max-width: ${tablet - 1}px) {
          width: 100%;
        }
      }
    }
  }
`;

export default ProductStyled;
