import styled from "styled-components";

import { GoBackStyledProps as Props } from "./GoBack.types";

const GoBackStyled = styled.div<Props>`
  width: ${props => (props.title ? "auto" : "2.4rem")};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .GoBack {
    &__icon {
      display: flex;
      align-items: center;

      path {
        fill: var(--palette-white);
      }
    }

    &__title {
      display: inline-block;
      width: ${props => (props.width ? props.width + "px" : "auto")};
      padding-left: 1rem;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.6rem;
      color: var(--palette-light-blue-s10-l60);
    }
  }
`;

export default GoBackStyled;
