import React, { ChangeEvent } from "react";

import Styles from "./CheckboxModifier.styles";
import { CheckboxModifierProps as Props } from "./CheckboxModifier.types";
import ModifierPrices from "../ModifierPrices/ModifierPrices";
import Checkbox from "components/global/Checkbox/Checkbox";

const CheckboxModifier: React.FC<Props> = props => {
  const { modifier, maxDisabled = false, disabled = false } = props;
  const { amount, name, totals, id } = modifier;
  const { handleChange, data } = modifier;
  const { prices } = data;
  const localDisabled = !amount && maxDisabled;

  const onChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    handleChange(isChecked ? 1 : 0);
  };

  const renderLabel = () => {
    return (
      <div className="CheckboxModifier__label">
        <p className="CheckboxModifier__title">{name}</p>
      </div>
    );
  };

  return (
    <Styles className="CheckboxModifier">
      <ModifierPrices name={name} totals={totals} prices={prices} />
      <Checkbox
        className="CheckboxModifier"
        label={renderLabel()}
        onChange={onChangeHandle}
        name={id.toString()}
        value={amount}
        defaultChecked={amount > 0}
        disabled={disabled ?? localDisabled}
      />
    </Styles>
  );
};

CheckboxModifier.defaultProps = {};

export default CheckboxModifier;
