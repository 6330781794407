import { useFetchCategoriesWithProducts } from "@multicines/services";
import { useCataloguesStore } from "@multicines/stores";
import { useProductsStore, useShoppingCartStore } from "@multicines/stores";
import { SNACKS_VENDOR } from "@multicines/utils";
import { getShoppingCartProductsByVendor } from "@multicines/utils";
import { getProductTotals } from "artisn/products";
import { BaseProduct, CartProduct } from "artisn/types";
import { Image, Slider } from "artisn-ui-react";
import PlusSVG from "images/plus.svg";
import React, { useMemo, useState } from "react";

import Styles from "./RecommendedSnacksList.styles";
import { RecommendedSnacksListProps as Props } from "./RecommendedSnacksList.types";
import Button from "components/global/Button/Button";
import ProductModal from "components/global/ProductModal/ProductModal";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useUtils from "hooks/useUtils";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { goToProductDetail } from "utils/seo.utils";

const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const RecommendedSnacksList: React.FC<Props> = props => {
  const t = useI18n();
  const { formatByCurrency } = useUtils();
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const selectedStore = useStoresStore(store => store.selectedStore);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const snackRestrictionCard = useProductsStore(
    store => store.snackRestrictionCard
  );
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const auth = { isAnonymous, uid };
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [productId, setProductId] = useState("");

  const snacks = useMemo(
    () => getShoppingCartProductsByVendor(shoppingCart, SNACKS_VENDOR.id),
    [shoppingCart]
  );
  const firstSnack = snacks?.[0];
  const { categories } = (firstSnack as CartProduct) ?? {};
  const [category] = categories ?? [];
  const { categoryId } = category ?? {};
  const [, snacksStore] = selectedStore ?? [];
  const { storeId } = snacksStore ?? {};
  const categoryResponse = useFetchCategoriesWithProducts(
    {
      catalogueId: selectedCatalogueId,
      storeId: storeId!,
      vendorId: SNACKS_VENDOR.id,
      page: 1,
      size: 10,
      productsByGroup: 1000,
      categoryId
    },
    auth
  );
  const { data: suggestProducts, status } = categoryResponse;

  const similarCategoryWithProducts = useMemo(() => {
    return suggestProducts?.pages.flatMap(page => page.data);
  }, [suggestProducts]);

  const similarProducts = useMemo(() => {
    return similarCategoryWithProducts?.[0]?.products ?? [];
  }, [similarCategoryWithProducts]);

  const onClickHandler = (product: BaseProduct) => {
    setProductId(product.productId);

    if (WITH_PRODUCT_MODAL) {
      setIsOpenModal(true);
    } else {
      goToProductDetail(product);
    }
  };

  if (!firstSnack) return null;

  return (
    <>
      <Styles className="RecommendedSnacksList">
        {status === "success" ? (
          <>
            <h2 className="RecommendedSnacksList__title">
              {t.repeatOrder.recommendedProduct}
            </h2>
            <Slider slideWidth={180} height={180} gap={16}>
              {similarProducts?.map(product => {
                const { productId, name, images } = product;
                const [image] = images ?? [];
                const { url } = image ?? {};
                const { netPrice } = getProductTotals(product, 1);
                const formattedPrice = formatByCurrency(netPrice);

                const { additionalInfo } = product ?? {};
                const { specialTicketType } = additionalInfo ?? {};

                if (
                  snackRestrictionCard !== undefined &&
                  specialTicketType !== undefined
                ) {
                  return null;
                }

                return (
                  <div
                    key={productId}
                    className="RecommendedSnacksList__wrapper"
                  >
                    {url ? (
                      <Image
                        image={url}
                        alt={name}
                        config={{
                          width: 180,
                          height: 180,
                          fit: "cover"
                        }}
                      />
                    ) : (
                      <div className="RecommendedSnacksList__image" />
                    )}
                    <div className="RecommendedSnacksList__info">
                      <p className="RecommendedSnacksList__text">{name}</p>
                      <p className="RecommendedSnacksList__text RecommendedSnacksList__text--price">
                        {formattedPrice}
                      </p>
                    </div>
                    <Button
                      onClick={() => onClickHandler(product)}
                      className="RecommendedSnacksList__button"
                    >
                      <PlusSVG className="RecommendedSnacksList__icon" />
                    </Button>
                  </div>
                );
              })}
            </Slider>
          </>
        ) : null}
      </Styles>
      {WITH_PRODUCT_MODAL ? (
        <ProductModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          productId={productId}
        />
      ) : null}
    </>
  );
};

RecommendedSnacksList.defaultProps = {};

export default RecommendedSnacksList;
