import styled from "styled-components";

import { AddToCartButtonStyledProps as Props } from "./AddToCartButton.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const AddToCartButtonStyled = styled.div<Props>`
  .AddToCartButton {
    &__text {
      color: var(--palette-black-s0-l10);

      &--desktop {
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${mobile - 1}px) {
          display: none;
        }
      }
    }
  }

  .Button {
    padding: 1.2rem 2.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    justify-content: space-between;
  }
`;

export default AddToCartButtonStyled;
