import { registerModifiersForm } from "artisn/products";
import { ModifiersForm } from "artisn/products";
import { ProductDetails } from "artisn/types";
import { useState, useEffect } from "react";

/**
 * Hook that registers the modifiers of a product and exposes them as a form.
 *
 * @since 0.1.0
 * @param {Product} product Product that contains modifiers
 * @returns {ModifiersForm} Form element that contains the modifiers in groups,
 * it also contains the product and the status of the chosen modifiers
 */
export const useProductForm = (product: ProductDetails | undefined) => {
  const [form, setForm] = useState<ModifiersForm>();

  useEffect(() => {
    if (!product || !product?.questions) return;

    const unsubscribe = registerModifiersForm(product, form => {
      setForm(form);
    });

    return unsubscribe;
  }, [product]);

  return form;
};
