import { ShoppingCartSummary } from "artisn-ui-react";
import styled from "styled-components";

import { ShoppingCartSummaryStyledProps as Props } from "./ShoppingCartSummary.types";

const ShoppingCartSummaryStyled = styled(ShoppingCartSummary)<Props>`
  .ShoppingCartSummary {
    &__title {
      display: none;
    }

    &__total {
      color: var(--palette-white);

      .ShoppingCartSummaryTotal {
        &__title {
          color: var(--palette-white);
          font-size: 1.4rem;
        }

        &__value {
          direction: rtl;
        }
      }
    }
  }
`;

export default ShoppingCartSummaryStyled;
